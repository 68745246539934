@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.App {
 width: 100%;
 margin:auto;
 font-family: Open+Sans!important;
}

.body{
  font-family: 'Open+Sans', sans-serif;

}

.App-logo {
  
  width: 50%;
  pointer-events: none;
}

.cent{
  text-align:   center; 
}
.color-nav{
  
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin  linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.margincar{padding-top: 3%;
         margin-top:1%;
         padding-bottom: 2%;
         margin-bottom: 20px}

body{
  padding-top: 45px;
}

header{
  margin-top: -25px;
}

.container1{
  width:80%;
  align-items: center;
  
  display:inline-flex;
  
  border:1px solid black;
}

.butasi a{
  text-decoration: none

}

.container2{
  width:75%;
  align-items: center;  text-align: justify;  margin-left: auto;  margin-right: auto;}

.container3{
  width:85%;
  align-items: center;  text-align: justify;  margin-left: auto;  margin-right: auto; margin-bottom: none; }

  .container5{
  width:90%;
  align-items: center;  text-align: justify; margin:auto; }

.container5 img{
  width:80%;
  align-items: center;  text-align: justify; margin:auto; }

  .container6{
  width:90%;
  align-items: center;  text-align: justify; margin:auto; }

.flex-direction{
  flex-direction:row;
}
.div1{
  margin: 3%;
  margin-top: 2%;
  margin-left:4%;
  margin-bottom: 2%;
  align-items: center;
  text-align: center;
  width:50%;
  
}


.div1 span{

  display: block;
  width:15%;
}

.div2{
  margin: 5%;
  text-align: justify;
  align-items: center;

  width:100%;
  margin-top: 2%;
  margin-bottom: 2%
}

.div3{
  margin:0% 3% 0% 3%;
  width:35%;
   align-items: center;
}
.div33{
  margin:0% 3% 0% 3%;
  width:12%;
   align-items: center;
}


.div2 img{
  width:90%;
  
}

.div2 span{
  display: block;
}


.div4{
  margin: 3%;
  margin-top: 2%;
  margin-left:4%;
  margin-bottom: 2%;
  align-items: center;
  text-align: center;
  width:45%;
  
}

.div5{
  
  width:30%;
   align-items: left;
   margin: 0% 2% 0% 2%;
}

.div5 ul{
  margin:0;
}
.div6{
  
  width:33%;
   align-items: center;
   margin: 0% 2% 0% 2%;
}

.div7{

  margin: 1% 3% 1% 2%;
  text-align: justify;
  width:45%;

}

.div8{
  
  width:25%;
   
   margin: 0% 5% 0% 4%;
}

.div9{
  
  width:50%;
   align-items: left;
   margin: 0% 2% 0% 2%;

}

.div10{
  
  width:40%;
   align-items: left;
   margin: 0% 0% 0% 6%;
}

.box {
  width:40%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  
}

.box > div {
  background-color: #FF4500;
  width: 100%;
  margin: 1%;
  text-align: center;
  line-height: 75px;
  font-size: 160%;
}

.bo{
  font-weight: bold;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
}

.title {
  color: grey;
  font-size: 18px;
}



a {
  text-decoration: none;
  font-size: 16px;
  color: black;
}

button:hover, a:hover {
  opacity: 0.6;
}

 .flex-container {
     
     display: flex;
     justify-content: space-between;
     align-items: center;

   }

   .horizontal {
     flex-direction: row;
     margin-left: 5%;
     width: 80%; /*this line was added*/
   }


.formatform{
  background-image: url("/assets/img/11.jpg");
  position: relative;
  overflow: hidden;
  background-size: cover;
}

.imgback{
    position:relative;
    background-image: url("/assets/img/11.jpg");
    background-size: cover;
    overflow: hidden;
}

.footer{padding:10px 0;width:100%; background-color: #000000; border-style: solid; border-color:#000000;text-align:center}
 .footer span.copyright{color:#D2691E;font-size:90%;line-height:40px;text-transform:none;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 .footer ul.quicklinks{cursor:pointer;color:#D2691E;font-size:90%;line-height:40px;margin-bottom:0;text-transform:none;font-family:Montserrat,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'}
 .footer ul.quicklinks a {color:#D2691E}
 ul.social-buttons{margin-bottom:0}
ul.social-buttons li a{font-size:20px;line-height:50px;display:block;width:50px;height:50px;transition:all .3s;color:#fff;border-radius:100%;outline:0;background-color:#000000}ul.social-buttons li a:active,ul.social-buttons li a:focus,ul.social-buttons li a:hover{background-color:#696969}
 

.sett{
  background-color: #D2691E;
  color:#D2691E;

}

.grey{
  background-color: #4e4d4d;
  

}

.grey #button{
  display: flex;
}

.orange{
background-color: #ea5b27;

}

.orange button{
  background-color: #ea5b27;
}

.imle{
  float: left;
  display: table-cell;
  vertical-align: middle;
   text-align: center;
}

.mar{
  margin-left:2%;
  padding: 1% 0 1% 0;
}

.bo2{
color: #ea5b27;
font-weight: bold;
}

.banim{;
      padding-bottom: 2%}

.banim img{
  padding-top: 2%
}

.banim2{padding-top: 2%;
        margin-top:1%; 
      padding-bottom: 2%}

.banim2 img{
  padding-top: 2%
}

.carruselbot{
  font-family: Helvetica;
  align-items: center;
  justify-content: center;
 padding-top: 1%;
      padding-bottom: 2%

}


button.rec-dot{
  background-color: #ea5b27;
  box-shadow: 0 0 1px 1px #ea5b27;
 
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 1px #ea5b27;
  
}




@media screen and (min-width: 602px) {
  .sizet p{
    font-size:1.4vw;
    text-align: justify;
  }

}


@media screen and (max-width: 601px) {
  .sizet p{
    margin: 0 5% 0 5%;
    
  }
}

@media screen and (max-width: 601px) {
  .sizet p{
    font-size: 2vw;
    
  }
}

@media screen and (max-width: 601px) {
  .div3 p{
    font-size: 2.5vw;
    
  }

  .conbi1{font-size: 1.5vw;}

  .introsec{font-size: 3.4vw; padding-top:4% }


  .div3{
  margin:0% 1% 0% 1%;
  width:40%;
   align-items: center;
}
.div33{
  margin:0% 1% 0% 1%;
  width:10%;
   align-items: center;
}

.containerflex{
        
 padding-bottom: 5%;
 padding-top: 5%;



      }

}

@media screen and (min-width: 602px) {


      .containerflex{
        display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
 flex-flow: row wrap;
 padding-bottom: 2%;
 padding-top: 1%;
 font-size: 1.2vw;
 justify-content: space-evenly;

      }
.marflex{
  margin-left: -60px;
  margin-right: 15px;
}

.marflex2{
  margin-left: -10px;
}

.marflex3{
  margin-left: -72px;
}
     .bi2 {
      flex-direction: row;
      margin: 0 9% 0 10%;
      font-family: Helvetica!important;
    }
    .picbi {
      order: 1;
      align-items: right;
    }

    .namebi{
     order: 2;
     text-align: center;
     font-size: 2vw;
     width: 100%   
    }

    .fontbi{
     margin: 0% 0% 0% 7%;
      order:3;  
    }

    .conbi{
      font-size: 1vw;
    }

    .fosi{
  font-size: 2vw
}

.conbi2{
      
      font-size: 1.4vw
    }

    .resi{
  width:90%;
}  


}

@media screen and (max-width: 601px) {
    .carousel-item{
      padding-top: 15%;
      margin-top: 8%;
    }

    .div8{
  
  width:33%;
   
   margin: none;
}

.container6{
  width: 100%;
}

    #banner a.carousel-control-prev,a.carousel-control-next {

      top:20%;
    font-size: 100%;
    


    }

    .bi2 {
      flex-direction: column;
      width:50%;
    }
    .picbi {
      order: 2;
      width: 80%;
      
    }

    .namebi{
     order:  1;
     text-align: center;
     align-items: center;
     width:80%;
    }

    .fontbi{
      flex-direction: row;
      order:3;
      font-size: 3.5vw;
      font-weight: bold;
      margin-left:0;
      width: 50%
      
    }
  
    .conbi{     
      font-size: 2.1vw
    }

    .conbi ul{
      margin:0;
    }    

    
   .logomov{   
    padding-right: 1%;
    text-align: center;
    align-items: flex-start;
    width: 80%;
   }
 #logoimg{   
    
    max-width: 50%!important;
    
   }

   .div8{
  width:31%;  
   margin: 0% 1% 0% 1%;
}
  .widfo{
    width: 70%;
  }

.resi{
  width:80%;
}  

}


.overlayt {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  font-size: 8vw;
  
  bottom: 2%;
  left: 8%;
}


.carousel-item {
  min-height: 100%;
  padding-left: 1px;
  margin-top: -20px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
}

.carousel-item{
  object-fit:cover;
  width: 100%;
  height: 100%;
}

.btn-primary{
  padding: 10px 20px;

}


 .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #ea5b27!important;
    border: #ea5b27!important;
    outline: none!important;
   box-shadow: none!important;
    
}

   
.btn:focus,.btn:active {
   outline: none!important;
   box-shadow: none!important;
}




.orban { width:1600px; z-index:10; color:#fff; text-align:right; top:43%;  bottom:auto;
 -webkit-transform:translate(0, -50%); -ms-transform:translate(0, -50%); transform:translate(0, -50%); }

#banner a.carousel-control-prev,a.carousel-control-next {

width: 10%;
font-size: 60px;
font-weight: bold;


}

